export const zgegData = [
  {
    date: '22/08/18',
    team1: ['Lucie', 'Loic', 10],
    team2: ['Mika P', 'Josue', -1],
  },
  {
    date: '29/08/18',
    team1: ['PA', 'Mika P', 10],
    team2: ['Salim', 'Josue', 0],
  },
  {
    date: '04/09/18',
    team1: ['Axel', 'Seb', 10],
    team2: ['Mika P', 'Josue', 0],
  },
  {
    date: '06/09/18',
    team1: ['Lucie', 'Tibo', 10],
    team2: ['Mika P', 'Josue', -1],
  },
  {
    date: '06/09/18',
    team1: ['Lucie', 'Tibo', 10],
    team2: ['Mika P', 'Josue', 0],
  },
  {
    date: '14/09/18',
    team1: ['PA', 'Seb', 10],
    team2: ['Mika P', 'Josue', 0],
  },
  {
    date: '04/10/18',
    team1: ['Loic', 10],
    team2: ['Mika P', 0],
  },
  {
    date: '04/10/18',
    team1: ['PA', 10],
    team2: ['Mika P', 0],
  },
  {
    date: '08/10/18',
    team1: ['Tibo', 'Alex', 10],
    team2: ['Quentin', 'Axel', 0],
  },
  {
    date: '24/10/18',
    team1: ['Tibo', 'Alex', 10],
    team2: ['Quentin', 'Antho', 0],
  },
  {
    date: '29/10/18',
    team1: ['Loic', 10],
    team2: ['Mika P', 0],
  },
  {
    date: '23/11/18',
    team1: ['Axel', 'Antho', 10],
    team2: ['Tibo', 'Alex', 0],
  },
  {
    date: '04/12/18',
    team1: ['Tibo', 'Quentin', 10],
    team2: ['Antho', 'Axel', 0],
  },
  {
    date: '12/12/18',
    team1: ['PA', 'Vincent', 10],
    team2: ['Mika P', 'Josue', 0],
  },
  {
    date: '18/12/18',
    team1: ['Tibo', 10],
    team2: ['Vincent', 0],
  },
  {
    date: '20/12/18',
    team1: ['PA', 10],
    team2: ['Vincent', -1],
  },
  {
    date: '20/12/18',
    team1: ['PA', 10],
    team2: ['Vincent', -1],
  },
  {
    date: '21/01/19',
    team1: ['Lucie', 'Tibo', 10],
    team2: ['Quentin', 'Axel', 0],
  },
  {
    date: '04/02/19',
    team1: ['Antho', 'Tibo', 10],
    team2: ['Quentin', 'Axel', 0],
  },
  {
    date: '29/08/19',
    team1: ['PA', 'Quentin', 10],
    team2: ['Arnaud', 'Lucas', 0],
  },
  {
    date: '22/11/19',
    team1: ['PA', 'Axel', 10],
    team2: ['Bastien', 'Alex', 0],
  },
  {
    date: '04/12/19',
    team1: ['Tibo', 'Quentin', 10],
    team2: ['Mehdi', 'PO', 0],
  },
  {
    date: '31/01/20',
    team1: ['AlexDev', 'Axel', 10],
    team2: ['Tibo', 'Alex', 0],
  },
  {
    date: '20/02/20',
    team1: ['AlexDev', 'PO', 10],
    team2: ['Axel', 'Patoch', 0],
  },
  {
    date: '28/02/20',
    team1: ['Tibo', 'Axel', 10],
    team2: ['AlexDev', 'Théo', 0],
  },
  {
    date: '01/04/21',
    team1: ['AlexDev', 'Grégoire', 10],
    team2: ['Bastien', 'PO', 0],
  },
  {
    date: '07/10/21',
    team1: ['Pierre', 10],
    team2: ['Véro', 'Paul', 0],
  },
  {
    date: '24/03/22',
    team1: ['Axel', 'Saïf', 10],
    team2: ['Sator', 'Aziza', 0],
  },
  {
    date: '06/04/22',
    team1: ['AlexDev', 'Quentin', 10],
    team2: ['Jalil', 'Bastien', 0],
  },
  {
    date: '28/04/22',
    team1: ['AlexDev', 10],
    team2: ['Véro', 0],
  },
  {
    date: '23/06/22',
    team1: ['AlexDev', 'Jalil', 10],
    team2: ['Aziza', 'Bénédicte', 0],
  },
  {
    date: '28/07/22',
    team1: ['Aziza', 10],
    team2: ['Valère', 0],
  },
  {
    date: '31/08/22',
    team1: ['Aziza', 10],
    team2: ['Dylan', 0],
  },
  {
    date: '13/09/22',
    team1: ['Aziza', 10],
    team2: ['Véro', 0],
  },
  {
    date: '03/11/22',
    team1: ['AlexDev', 'Audrey', 10],
    team2: ['Saïf', 'Lisa', 0],
  },
  {
    date: '03/11/22',
    team1: ['Dylan', 10],
    team2: ['Marvin', 0],
  },
  {
    date: '16/11/22',
    team1: ['Saïf', 10],
    team2: ['Marvin', 0],
  },
  {
    date: '06/12/22',
    team1: ['Saïf', 'Marvin', 10],
    team2: ['Élodie', 'Christian', 0],
  },
  {
    date: '11/01/23',
    team1: ['AlexDev', 'Axel', 10],
    team2: ['Saïf', 'Léo', 0],
  },
  {
    date: '25/01/23',
    team1: ['Léo', 'Saïf', 10],
    team2: ['Véro', 'Nico', 0],
  },
  {
    date: '26/01/23',
    team1: ['Genest', 10],
    team2: ['Marvin', 0],
  },
  {
    date: '22/02/23',
    team1: ['Paul', 'Manu', 10],
    team2: ['Véro', 'Genest', 0],
  },
  {
    date: '22/02/23',
    team1: ['Saïf', 10],
    team2: ['Charlotte', 0],
  },
  {
    date: '23/02/23',
    team1: ['AlexDev', 'Bénédicte', 10],
    team2: ['Dylan', 'Marvin', 0],
  },
  {
    date: '11/04/23',
    team1: ['Léo', 'Saïf', 10],
    team2: ['Marvin', 'Dylan', 0],
  },
  {
    date: '18/04/23',
    team1: ['Léo', 'Saïf', 10],
    team2: ['Véro', 'Charlotte', 0],
  },
  {
    date: '15/05/23',
    team1: ['Véro', 'Mahmoud', 10],
    team2: ['Alexandre B', 'Paul', 0],
  },
  {
    date: '22/06/23',
    team1: ['AlexDev', 'Ludo', 10],
    team2: ['Béné', 'Axel', 0],
  },
  {
    date: '26/07/23',
    team1: ['Véro', 'Angeline', 10],
    team2: ['Paul', 'Deborah', 0],
  },
  {
    date: '09/08/23',
    team1: ['Angéline', 'Saïf', 10],
    team2: ['Véro', 'Jeanne', 0],
  },
  {
    date: '17/08/23',
    team1: ['Saïf', 10],
    team2: ['Angéline', 0],
  },
  {
    date: '28/08/23',
    team1: ['Saïf', 10],
    team2: ['Charlotte', 0],
  },
  {
    date: '28/09/23',
    team1: ['Saïf', 'Angeline', 10],
    team2: ['Charlotte', 'Pauline', 0],
  },
  {
    date: '10/10/23',
    team1: ['Saïf', 'Romain', 10],
    team2: ['Charlotte', 'Pauline', 0],
  },
  {
    date: '07/11/23',
    team1: ['Saïf', 'Romain', 10],
    team2: ['Charlotte', 'Pauline', 0],
  },
  {
    date: '09/11/23',
    team1: ['Saïf', 'Romain', 10],
    team2: ['Hugo', 'Pauline', 0],
  },
  {
    date: '19/12/23',
    team1: ['Axel', 'Matthieu', 10],
    team2: ['Valère', 'Charlotte', 0],
  },
  {
    date: '20/02/24',
    team1: ['Saïf', 'Manu', 10],
    team2: ['Sarra', 'Pauline', 0],
  },
  {
    date: '22/02/24',
    team1: ['Saïf', 'Hugo', 10],
    team2: ['Bénédicte', 'Pauline', 0],
  },
  {
    date: '22/02/24',
    team1: ['AlexDev', 'Manu', 10],
    team2: ['Bénédicte', 'Pauline', 0],
  },
  {
    date: '14/03/24',
    team1: ['Saïf', 10],
    team2: ['Angéline', 0],
  },
  {
    date: '21/03/24',
    team1: ['Saïf', 'Marine', 10],
    team2: ['Arnaud P', 'Miguel', 0],
  },
  {
    date: '26/03/24',
    team1: ['Saïf', 10],
    team2: ['Angéline', 0],
  },
  {
    date: '26/03/24',
    team1: ['Saïf', 10],
    team2: ['Hugo', 0],
  },
].reverse() as Array<{
  date: string;
  team1: [string, string, number];
  team2: [string, string, number];
}>;
